import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { navigationHome } from '@utils/navigation';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 pt-60 px-10 text-center
    md:w-auto md:text-left md:pb-310
    lg:pt-130 lg:px-0 lg:pb-428
  `}
`;

const Oval = styled.div`
  top: 5px;
  left: -85px;
  ${tw`absolute w-343 h-343 rounded-full bg-pink -z-1`}
  
  @media (min-width: 768px) {
    top: 14px;
    left: -135px;
  }
`;

const Header = styled(Text)`
  ${tw`md:w-400 lg:w-460`}

  br {
    ${tw`hidden md:block`}

    &:nth-of-type(1) { ${tw`md:hidden`} }
  }
`;

const Image = styled(Img)`
  left: -110px;
  ${tw`w-520 mt-25 -z-1 md:absolute! md:w-740 lg:w-1177`}
  
  @media (min-width: 768px) {
    top: 10px;
    left: 95px;
  }

  @media (min-width: 1024px) {
    top: -25px;
    left: 95px;
  }
`;

const Link = tw(LinkDefault)`inline-block mt-25 lg:mt-30`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-30 bg-404`;

const NotFoundPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      notFound: file(relativePath: { eq: "404.png" }) {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout alternativeFooter>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.not found' })}
      />
      <Wrapper>
        <Oval />
        <Header
          fontFamily="bebasNeue"
          fontSizes={[42, 56]}
          fontWeight={300}
          lineHeight={1.2}
          letterSpacing={[53, 70]}
        >
          <FormattedHTMLMessage id="404.header" />
        </Header>
        <Image fluid={data.notFound.childImageSharp.fluid} />
        <Link to={navigationHome.path} isExternal={navigationHome.externalLink}>
          <Button>
            <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
              <FormattedMessage id={navigationHome.translation} />
            </Text>
          </Button>
        </Link>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
